.product-item {
    padding: 10px 0;

    .imageBox {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .image {
        height: auto;
        width: 100%;
    }

    .productName {
        font-size: 14px;
        padding: 8px 0px 5px 10px;
        font-weight: 500;
        line-height: 16px;
        font-family: $secondary-font;
    }

    .productPrice {
        display: flex;
        align-items: baseline;
        padding-bottom: 0px;
    }

    .productMrp {
        font-size: 12px;
        padding: 0px 18px 0px 1px;
        font-weight: 500;
        font-family: $primary-font;
        color: gray;
        text-decoration: line-through;
    }

    .productOfferprice {
        font-size: 16px;
        padding: 0px 10px 0px 12px;
        color: #1DA1F2;
        font-weight: 500;
        font-family: $primary-font;
    }

    .productOff {
        font-size: 10px;
        font-weight: 500;
        border: 1.5px solid #1DA1F2;
        padding: 0px 7px 0px 7px;
        margin: 0px !important;
        color: $primary-color;
        border-radius: 6px;
        border-bottom-left-radius: 0px;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .bestCouponBox {
        background-color: #F7F5FF;
        // border-left: 7px solid $primary-color;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        margin: 4px 0 0 0px;
    }

    .bestCouponAmount {
        padding: 5px 7px 5px 7px;
    }

    .bestPercentageOff {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 7px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        padding: 5px 7px 5px 7px;
        background-color: $primary-color;
        font-weight: bold;
        color: #fff;
        font-size: 14px !important;
    }

    .bestCouponTag {
        font-size: 14px !important;
        font-family: $primary-font;
        display: flex;
        justify-content: space-between;
    }

    .bestCouponCode {
        font-size: 12px;
    }

    .bestCoupon {
        padding: 0 0 0 0;
    }

    .bestCouponOff {
        font-size: 10px;
        font-weight: 500;
        border: 1.5px solid #1DA1F2;
        padding: 0px 7px 0px 7px;
        margin: 0px !important;
        color: $primary-color;
        border-radius: 6px;
        border-bottom-left-radius: 0px;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .veg-nonveg {
        width: 10%;
        height: 100%;
    }

    .addedBtn {
        background-color: $primary-color;
        font-family: $secondary-font;
        color: #fff;
        text-align: center;
        width: 50%;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        border-radius: 50px;
        padding: 6px 12px 6px 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .addedNumber {
            position: absolute;
            right: 2px;
            background-color: #fff;
            color: $primary-color;
            border-radius: 50%;
            padding: 4px 8px;
        }
    }

    .notAddedBtn {
        background-color: $primary-color;
        font-family: $secondary-font;
        color: #fff;
        text-align: center;
        width: 50%;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        border-radius: 50px;
        padding: 4px 12px 4px 12px;
        display: flex;
        justify-content: center;

        .arrowBtn {
            position: absolute;
            right: 2px;
            color: #fff;
        }
    }

    .exhaustedBtn {
        background-color: #fff;
        font-family: $secondary-font;
        color: #1DA1F2;
        text-align: center;
        width: 50%;
        position: relative;
        cursor: pointer;
        border-radius: 50px;
        padding: 4px 10px 4px 10px;
        border: 1px solid #A8A8A8;
        margin: 4px 0 0 0;

        .exhaustedText {
            font-size: 13px;
        }
    }
}

.exhausted {
    .imageBox {
        .image {
            filter: saturate(0.4);
        }
    }

    .productName,
    .productPrice,
    .productMrp,
    .productOff {
        color: #A8A8A8;
    }
}

@media screen and (min-width: 350px) {
    .product-item {
        padding: 10px 0;

        .productName {
            font-size: 16px;
            padding: 8px 0px 5px 10px;
        }

        // .productMrp{
        //     font-size: 18px;
        //     padding: 0px 18px 0px 12px;
        // }
        .addedBtn {
            width: 50%;
            font-size: 16px;
            padding: 8px 12px 8px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 8px;
            }
        }

        .notAddedBtn {
            font-size: 16px;
            padding: 4px 12px 4px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            width: 50%;
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 15px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .product-item {
        padding: 0px 40px;
        height: 100%;
        width: auto;

        .productBox {
            padding: 30px 0 10px 0;
        }

        .imageBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .image {
            height: auto;
            width: 60%;
        }

        .veg-nonveg {
            margin: 0% 0 0% 6px;
            width: 9%;
            height: 100%;
        }

        .productName {
            font-size: 18px;
            padding: 8px 0px 5px 0px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .productPrice {
            padding: 4px 0 7px 0;
        }

        .productOfferprice {
            font-size: 18px;
            padding: 0px 4px 0px 0px;
        }

        .productMrp {
            font-size: 14px;
            padding: 0px 8px 0px 1px;
        }

        .productOff {
            font-size: 10px;
            padding: 0px 5px 0px 5px;
            margin: 0px !important;
        }

        .addedBtn {
            font-size: 16px;
            padding: 4px 12px 4px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 8px;
            }
        }

        .notAddedBtn {
            font-size: 16px;
            padding: 6px 12px 6px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 16px;
            }
        }

        .addedBtn,
        .notAddedBtn,
        .exhaustedBtn {
            width: 100% !important;
        }


        .bestCouponBox {
            background-color: #F7F5FF;
            // border-left: 7px solid $primary-color;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            margin-top: 2px;
            // padding: 5px 0 5px 7px;
        }

        .bestCouponAmount {
            padding: 5px 7px 5px 7px;
        }

        .bestPercentageOff {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 7px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            padding: 5px 7px 5px 7px;
            background-color: $primary-color;
            font-weight: bold;
            color: #fff;
            font-size: 14px !important;
        }

        .bestCouponTag {
            font-size: 14px !important;
            font-family: $primary-font;
            display: flex;
            justify-content: space-between;
        }

        .bestCouponCode {
            font-size: 12px;
            padding-bottom: 7px;
        }

        .bestCoupon {
            padding: 0 0 0 0;
        }

        .bestCouponOff {
            font-size: 10px;
        }

        .emptyBox{
            height: 55px;
        }
    }
}

@media screen and (min-width: 992px) {
    .product-item {
        padding: 0px 10px;

        .productBox {
            padding: 15px 0 10px 0;
        }

        .image {
            width: 60%;
        }

        .veg-nonveg {
            margin: 0% 0 0% 6px;
            width: 11%;
        }

        .productName {
            font-size: 16px;
            padding: 8px 0px 2px 0px;
        }

        .productPrice {
            padding: 2px 0 5px 0;
        }

        .productOfferprice {
            font-size: 16px;
            padding: 0px 4px 0px 0px;
        }

        .productMrp {
            font-size: 12px;
            padding: 0px 8px 0px 1px;
        }

        .productOff {
            font-size: 10px;
            padding: 0px 5px 0px 5px;
            margin: 0px !important;
        }

        .addedBtn {
            font-size: 16px;
            padding: 7px 12px 7px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 8px;
            }
        }

        .notAddedBtn {
            font-size: 14px;
            padding: 5px 12px 5px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 16px;
            }
        }

        .bestCouponBox {
            margin-top: 2px;
        }

        .bestCouponAmount {
            padding: 5px 7px 5px 7px;
        }

        .bestPercentageOff {
            padding: 5px 7px 5px 7px;
            font-size: 9px !important;
        }

        .bestCouponTag {
            font-size: 10px !important;
        }

        .bestCouponCode {
            font-size: 11px;
            padding-bottom: 7px;
        }

        .bestCoupon {
            padding: 0 0 0 0;
        }

        .bestCouponOff {
            font-size: 8px;
        }

        .emptyBox{
            height: 43px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .product-item {
        padding: 0px 25px;

        .productBox {
            padding: 10px 0 15px 0;
        }

        .image {
            width: 65%;
        }

        .veg-nonveg {
            margin: 0% 0 0% 8px;
            width: 11%;
        }

        .productName {
            font-size: 18px;
            padding: 8px 0px 2px 0px;
        }

        .productPrice {
            padding: 4px 0 5px 0;
        }

        .productOfferprice {
            font-size: 18px;
            padding: 0px 4px 0px 0px;
        }

        .productMrp {
            font-size: 14px;
            padding: 0px 8px 0px 1px;
        }

        .productOff {
            font-size: 10px;
            padding: 0px 5px 0px 5px;
            margin: 0px !important;
        }

        .addedBtn {
            font-size: 16px;
            padding: 7px 12px 7px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 8px;
            }
        }

        .notAddedBtn {
            font-size: 14px;
            padding: 5px 12px 5px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 16px;
            }
        }

        .bestCouponBox {
            margin-top: 2px;
        }

        .bestCouponAmount {
            padding: 5px 7px 5px 7px;
        }

        .bestPercentageOff {
            padding: 5px 7px 5px 6px;
            font-size: 12px !important;
        }

        .bestCouponTag {
            font-size: 12px !important;
        }

        .bestCouponCode {
            font-size: 12px;
            padding-bottom: 7px;
        }

        .bestCoupon {
            padding: 0 0 0 0;
        }

        .bestCouponOff {
            font-size: 12px;
        }

        .emptyBox{
            height: 50px;
        }
    }
}

@media screen and (min-width: 1400px) {
    .product-item {
        padding: 0px 35px;

        .productBox {
            padding: 20px 0 20px 0;
        }

        .image {
            width: 65%;
        }

        .veg-nonveg {
            margin: 0% 0 0% 8px;
            width: 11%;
        }

        .productName {
            font-size: 18px;
            padding: 8px 0px 2px 0px;
        }

        .productPrice {
            padding: 4px 0 5px 0;
        }

        .productOfferprice {
            font-size: 18px;
            padding: 0px 4px 0px 0px;
        }

        .productMrp {
            font-size: 14px;
            padding: 0px 8px 0px 1px;
        }

        .productOff {
            font-size: 10px;
            padding: 0px 5px 0px 5px;
            margin: 0px !important;
        }

        .addedBtn {
            font-size: 18px;
            padding: 7px 12px 7px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 8px;
            }
        }

        .notAddedBtn {
            font-size: 18px;
            padding: 7px 12px 7px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 18px;
            }
        }

        .bestCouponAmount {
            padding: 5px 7px 5px 7px;
        }

        .bestPercentageOff {
            font-size: 12px !important;
        }

        .bestCouponTag {
            font-size: 14px !important;
        }

        .bestCouponCode {
            font-size: 14px;
            padding-bottom: 10px;
        }

        .bestCouponOff {
            font-size: 14px;
        }

        .emptyBox{
            height: 57px;
        }
    }
}

@media screen and (min-width: 1920px) {
    .product-item {
        padding: 0px 60px;

        .productBox {
            padding: 18px 0 20px 0;
        }

        .image {
            width: 70%;
        }

        .veg-nonveg {
            margin: 0% 0 0% 12px;
            width: 12%;
        }

        .productName {
            font-size: 24px;
            padding: 12px 0px 8px 0px;
        }

        .productPrice {
            padding: 4px 0 5px 0;
        }

        .productOfferprice {
            font-size: 24px;
            padding: 0px 4px 0px 0px;
        }

        .productMrp {
            font-size: 18px;
            padding: 0px 8px 0px 1px;
        }

        .productOff {
            font-size: 10px;
            padding: 0px 5px 0px 5px;
            margin: 0px !important;
        }

        .addedBtn {
            font-size: 24px;
            padding: 7px 12px 7px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 12px;
            }
        }

        .notAddedBtn {
            font-size: 24px !important;
            padding: 10px 12px 10px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 23px;
            }
        }

        .bestCouponAmount {
            padding: 5px 7px 5px 7px;
        }

        .bestPercentageOff {
            padding: 5px 10px 5px 10px;
            font-size: 16px !important;
        }

        .bestCouponTag {
            font-size: 18px !important;
        }

        .bestCouponCode {
            font-size: 18px;
            padding-bottom: 10px;
        }

        .bestCouponOff {
            font-size: 14px;
        }

        .emptyBox{
            height: 66px;
        }
    }
}